import moment from "moment-timezone";
import 'moment/dist/locale/de';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/es';
import 'moment/dist/locale/fi';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/it';
import 'moment/dist/locale/nl';
import 'moment/dist/locale/pt';
import 'moment/dist/locale/se';
import 'moment/dist/locale/th';
import 'moment/dist/locale/tr';

import {usePage} from '@inertiajs/vue3';
import {PageWithSharedProps} from "./Types/inertia";
import confetti from 'canvas-confetti';
import {Tracker} from "@tiptap/vue-3";

export enum State {
    'saveTheDate' = 'saveTheDate',
    'invitation' = 'invitation',
    'thankYou' = 'thankYou',
}

export function useMixin() {
    // @ts-ignore
    const route = window.route;
    const generateStatamicRoute = (routeName: string): string | null => {
        return usePage<PageWithSharedProps>().props.statamic_routes[routeName] ?? null;
    };

    const __ = (key: string, replace?: string) => {
        const translations = usePage<PageWithSharedProps>().props ?
            usePage<PageWithSharedProps>().props.language : window.translations;

        let translation = translations[key] ? translations[key] : key;

        if (replace) {
            Object.keys(replace).forEach(function (key: string) {
                // @ts-ignore
                translation = translation.replace(':' + key, replace[key])
            });
        }

        return translation;
    };

    const __choice = (key: string, replace = {}, count: number) => {
        function getPluralForm(count: number, translation: string) {
            const pluralForms = translation.split('|');

            for (let i = 0; i < pluralForms.length; i++) {
                const [range, ...formParts] = pluralForms[i].trim().split(' ');
                const form = formParts.join(' ');
                const [start, end] = range.replace('{', '').replace('}', '').replace('[', '').replace(']', '').split(',').map(s => s.trim().toLowerCase() === 'inf' ? Infinity : Number(s.trim()));

                if ((end === undefined && count === start) || (count >= start && (end === Infinity || count <= end))) {
                    return form.trim();
                }
            }

            // Fallback if no plural form matches
            return translation;
        }

        // Fetch the translation string from the language dictionary
        var translation = usePage<PageWithSharedProps>().props.language[key];

        if (!translation) {
            return key;
        }

        // Get the correct plural form based on the count
        if (translation.includes('|')) {
            translation = getPluralForm(count, translation);
        }

        // Replace placeholders in the translation string
        Object.keys(replace).forEach(function (key) {
            // @ts-ignore
            translation = translation.replace(new RegExp(':' + key, 'g'), replace[key]);
        });

        return translation.replace(':count', count);
    };

    const mix = (filename: string) => {
        const entries: any = usePage().props.mix ?? [];

        if (Object.keys(entries).length === 0) {
            return 'http://localhost:5173/' + filename;
        }

        return `/build/${entries[filename].file}`;
    };

    const isSameAppVersion = (wedding: any, version: number): boolean => {
        if (wedding.user === undefined) {
            return 1 === version;
        }

        return wedding.user.version === version;
    };

    const isMinimumAppVersion = (wedding: any, version: number): boolean => {
        return wedding.user.version >= version && wedding.user.version !== 1;
    };

    const getLegacyAddress = (card: App.Models.Card) => {
        let ceremony = card.wedding_schedules.filter(x => /(^Trauung$)/.test(x.name))[0] ?? null;

        if (ceremony && ceremony.location) {
            return ceremony.location;
        }

        let party = card.wedding_schedules.filter(x => x.name === 'Hochzeitsfeier')[0] ?? null;

        if (party && party.location) {
            return party.location;
        }

        return null;
    };

    const getAddress = (card: App.Models.Card) => {
        if (getLegacyAddress(card) !== null && isSameAppVersion(card, 1)) {
            return getLegacyAddress(card);
        }

        let block = card.blocks.filter(x => x.type === 'arrival')[0];

        // @ts-ignore
        if (block !== undefined && block.data.weddingScheduleId === null && block.data.addressCustom) {
            // @ts-ignore
            return block.data.addressCustom;
        }

        // @ts-ignore
        if (block !== undefined && block.data.weddingScheduleId) {
            // @ts-ignore
            let dynamicBlock = card.wedding_schedules.filter(x => x.id === block.data.weddingScheduleId)[0];

            if (dynamicBlock && dynamicBlock.location) {
                return dynamicBlock.location;
            }
        }

        let last = card.wedding_schedules[0];

        return last.location;
    };

    const getDirections = (card: App.Models.Card): string => {
        if (card.blocks.filter(x => x.type === 'directions')[0]) {
            // @ts-ignore
            return card.blocks.filter(x => x.type === 'directions')[0].data['text'];
        }

        // @ts-ignore
        if (card.blocks.filter(x => x.type === 'arrival')[0]) {
            return card.blocks.filter(x => x.type === 'arrival')[0].data['description'];
        }

        return null;
    };

    const getSalutation = (invitation: App.Models.Invitation): string => {
        if (invitation.guests.length > 1 && invitation.card && invitation.card.language === 'es') {
            return __('invitation.salutation.plural');
        }

        if (invitation.guests.length > 1 && invitation.card && invitation.card.language === 'fr') {
            return __('invitation.salutation.plural');
        }

        if (invitation.salutation === 'male') {
            return __('invitation.salutation.male');
        }

        if (invitation.salutation === 'female') {
            return __('invitation.salutation.female');
        }

        return __('invitation.salutation.unknown');
    };

    const getImagesFromCallery = (card: App.Models.Card): string[] => {
        const block = card.blocks.filter(x => x.type === 'gallery')[0];

        // @ts-ignore
        if (block.data.images.length > 0) {
            // @ts-ignore
            return block.data.images;
        }

        return [
            mix('resources/images/gallery/gallery-example-01.jpg'),
            mix('resources/images/gallery/gallery-example-02.jpg'),
            mix('resources/images/gallery/gallery-example-03.jpg'),
            mix('resources/images/gallery/gallery-example-04.jpg'),
        ];
    };

    const getDate = (date: Date, format: string, locale: string) => {
        moment.locale(locale);

        return moment(date).tz('Europe/Berlin').format(format);
    };


    const getTime = (time: Date, card: App.Models.Card): string => {
        let response: string | any = getDate(time, 'HH:mm', card.language);

        if (card.language === 'de') {
            response = getDate(time, 'HH:mm', card.language) + ' Uhr';
        }

        if (card.language === 'en') {
            response = getDate(time, 'hh:mm a', card.language);
        }

        if (card.language === 'fr') {
            response = getDate(time, 'HH', card.language) + 'h' + getDate(time, 'mm', card.language);
        }

        if (card.language === 'tr') {
            response = getDate(time, 'HH:mm', card.language) + ' Saat';
        }

        if (card.language === 'hr') {
            response = getDate(time, 'HH:mm', card.language) + ' Sati';
        }

        return response;
    };

    const address = (data: any) => {
        if (data === null) {
            return data;
        }

        return data.replace(/,/g, '<br />');
    }

    const generateRoute = (routeName: string, invitation: App.Models.Invitation, params: any): string => {
        let parts = routeName.split('app.invitation.');
        params['cardType'] = 'invitation';

        let newRoute = routeName;

        // Preview e.g. http://mi-boda.development/karte/martina-philipp-pbgow/vorschau
        if (invitation.demo_route === 'app.design_invitation.preview') {
            newRoute = parts.join('app.invitation.preview.');
            params['invitation'] = null;
        }

        // Demo e.g. http://mi-boda.development/karte/martina-philipp-pbgow/vorschau/demo
        if (invitation.demo_route === 'app.design_invitation.preview.demo') {
            newRoute = parts.join('app.invitation.demo.');
            params['invitation'] = null;
        }

        // Demo (unauthorized) e.g. http://mi-boda.development/designs/elegant/demo?theme=gold
        if (invitation.demo_route === 'app.designs.demo') {
            newRoute = parts.join('app.invitation.demo_unauthorized.');
            params['design'] = invitation.card.design;
            params['theme'] = invitation.card.theme;
        }

        if (invitation.demo_route) {
            params['demo_route'] = invitation.demo_route;
        }

        return route(newRoute, params);
    };

    const getColorByThemeAndOption = (theme: string | null, option: string): string => {
        if (theme === null) {
            return usePage<PageWithSharedProps>().props.design.variants['default'][option];
        }

        return usePage<PageWithSharedProps>().props.design.variants[theme][option];
    };

    const linkify = (text: string) => {
        // URLs starting with http://, https://, or ftp://
        var replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        var replacedText = text.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

        // URLs starting with www. (without // before it, or it'd re-link the ones done above)
        var replacePattern2 = /(^|\s)(www\.[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/[^\s]*)?)/gim;
        var replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

        // Change email addresses to mailto:: links
        var replacePattern3 = /(([a-zA-Z0-9_\-\.]+)@[a-zA-Z_]+?(?:\.[a-zA-Z]{2,6}))+/gim;
        var replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

        return replacedText;
    };

    const formatGuestNames = (names: string): string => {
        return names
            .replace(/&/g, "<br/>&<br/>")
            .replace(/,/g, ",<br/>");
    };

    function fire(particleRatio: number, opts: object) {
        var count = 200;
        var defaults = {
            origin: {y: 0.7}
        };

        confetti({
            // @ts-ignore
            ...defaults,
            ...opts,
            particleCount: Math.floor(count * particleRatio)
        });
    }

    const party = (): void => {
        fire(0.25, {
            spread: 26,
            startVelocity: 55,
        });
        fire(0.2, {
            spread: 60,
        });
        fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    };

    return {
        route,
        __,
        __choice,
        mix,
        isSameAppVersion,
        isMinimumAppVersion,
        getDate,
        getTime,
        address,
        generateRoute,
        generateStatamicRoute,
        getColorByThemeAndOption,
        getAddress,
        getDirections,
        getSalutation,
        getImagesFromCallery,
        formatGuestNames,
        linkify,
        party,
    };
}

export default useMixin();
